import './App.css';
// import ActiveWebsite from './components/ActiveWebsite';
import LandingPage from './views/LandingPage';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <ActiveWebsite></ActiveWebsite> */}
        <LandingPage></LandingPage>
        
      </header>
    </div>
  );
}

export default App;
